import SectionHeading from "../SectionHeading";
import React, { useState } from "react";
import "./Roadmap.scss";
import { elephantLogo } from "../../utils/allImgs";
import RoadmapCard from "../RoadmapCard";
import { roadmapCardData } from "../../data/data-roadmap/data-roadCard";
const Roadmap = ({ data, ClassSpanTitle }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  return (
    <section className="roadmap_main" id="roadmap">
      <div className="mask_effect_roadmap"></div>
      <div className="container_roadmap">
        <div className="section_title">
          <span style={{ zIndex: 99 }}>RoadMap</span>
        </div>
        {roadmapCardData.map((item, id) => (
          <RoadmapCard
            key={id}
            date={item.date}
            type={item.type}
            index={item.index}
            img={item.img}
            content={item.content}
          />
        ))}
      </div>
    </section>
  );
};

export default Roadmap;
