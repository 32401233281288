import { RiTwitterXFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import "./Footer.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useModal } from "../../ContextProvider";

const Footer = ({}) => {
  const { setIsOpen, isOPen } = useModal();

  return (
    <div className="footer_flag">
      <div className="footer_mask"></div>
      <div className="footer_content">
        <div className="footer_title text-center">
          OF THE CRYPTO, BY THE CRYPTO, FOR THE CRYPTO!
        </div>
        <div className="footer_text text-center">
          Please be advised that Elumphant is a cryptocurrency created for
          entertainment purposes and is not affiliated with or endorsed by any
          celebrities or political party. While Elumphant may reference or
          incorporate elements related any individual’s persona, image, or
          likeness, it does not imply any direct endorsement, partnership, or
          approval by them. Any resemblance or association is purely
          coincidental and intended for satirical or humorous purposes
        </div>
        <div className="footer_social_btns">
          <div
            className="footer_social_btn"
            onClick={() => {
              window.open("https://x.com/elumphant", "_blank");
            }}
            id="socialBtn"
          >
            <RiTwitterXFill size={16} />
          </div>
          <div
            className="footer_social_btn"
            onClick={() => {
              window.open("https://t.me/elumphant_public", "_blank");
            }}
            id="TelegramBtn"
          >
            <FaTelegramPlane color={""} size={16} />
          </div>
        </div>
        <div className="" id="header_buy_btn">
          <a className="buy_btn" href="/#home">
            Buy $Elump
          </a>
        </div>
        <div className="footer_btns">
          <div className="footer_btn">
            <a className="footer_btn" href="/#home">
              Home
            </a>
          </div>
          <div className="footer_btn">
            <a className="footer_btn" href="/#about">
              About
            </a>
          </div>
          <div className="footer_btn">
            <Link className="footer_btn" to="/how-to-buy">
              How To Buy
            </Link>
          </div>
          <div className="footer_btn">
            <a className="footer_btn" href="/#whitepaper">
              WhitePaper
            </a>
          </div>
          <div className="footer_btn">
            <a className="footer_btn" href="/#roadmap">
              RoadMap
            </a>
          </div>
          <div className="footer_btn">
            {/* <Link className="footer_btn" to="/stacking">
              Staking
            </Link> */}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsOpen(true);
              }}
              className="footer_btn"
            >
              Staking
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
