// import SectionHeading from "../SectionHeading"
// import {bubbleImg} from '../../utils/allImgs'
// import PieChart from "../PieChart";
import React, { useState } from "react";
import "./WhitePaper.scss"
import { elephantLogo, whitepaperGIF } from "../../utils/allImgs";

const WhitePaper = () => {

  return (
    <section className="whitepaper_container" id="whitepaper">
      <div className="mask_effect_whitepaper" />
      <div className="whitepaper_left">
        <div className="whitepaper_title">
          Elumphant's Battle for Meme Freedom
        </div>
        <div className="whitepaper_content">Welcome to Elumphant—the mighty, muscular meme elephant who marches to triumphant! With Trump's boldness, Elon's ambition, and a spirit of unyielding strength, Elumphant is here to storm the crypto jungle. Its motto: <span style={{ fontWeight: "bold", color: "gold" }}>OF THE CRYPTO, BY THE CRYPTO, FOR THE CRYPTO!</span> Join the journey—this adventure is unstoppable!</div>
        <div className="whitepaper_btn">
          <a  href="/whitepaper.pdf" target="_blank" rel="noopener noreferrer">WhitePaper</a>
        </div>
      </div>
      <div className="whitepaper_right">
        <img src={whitepaperGIF} width={"80%"} alt="" />
      </div>
    </section>
  );

}

export default WhitePaper;