import { EthersAdapter } from "@reown/appkit-adapter-ethers";
import { createAppKit } from "@reown/appkit";
import {
  mainnet,
  arbitrum,
  sepolia,
  bsc,
  solana,
  solanaTestnet,
  solanaDevnet,
  bscTestnet,
} from "@reown/appkit/networks";
// Get projectId from https://cloud.reown.com
export const projectId = process.env.REACT_APP_PROJECT_ID;

if (!projectId) {
  throw new Error("Project ID is not defined");
}

// Create a metadata object - optional
export const metadata = {
  name: "Elumphant",
  description: "AppKit Example",
  url: "https://elumphant.com/", // origin must match your domain & subdomain
  icons: ["https://assets.reown.com/reown-profile-pic.png"],
};

// for custom networks visit -> https://docs.reown.com/appkit/react/core/custom-networks
export const networks = [mainnet, arbitrum, solana, bsc, sepolia, bscTestnet];

// Set up Solana Adapter
export const ethersAdapter = new EthersAdapter();
