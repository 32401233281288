export const HowToStartInfo = [
    {
        num:1,
        title:"Register New Account",
        text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit."
    },
    {
        num:2,
        title:"Setup Account Info",
        text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit."
    },
    {
        num:3,
        title:"Start Buying &amp; Selling",
        text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla neque quam, max imus ut accumsan ut, posuere sit."
    }
]

export const OurTeamInfo = [
    {
        title:"Joman Helal",
        text:"Executive Officer"
    },
    {
        title:"Slans Alons",
        text:"Business Development"
    },
    {
        title:"Josha Michal",
        text:"UX/UI Designer"
    },
    {
        title:"Johan Wright",
        text:"Head of Marketing"
    }
]