import SectionHeading from "../SectionHeading";
import {
  bnbIcon,
  bnbLogo,
  bubbleImg,
  etherIcon,
  ethLogo,
  solanaLogo,
} from "../../utils/allImgs";
import React, { useState, useEffect } from "react";
import { useModal } from "../../ContextProvider";
import Modal from "../Modal";
import "./HowToBuy.scss";
// import FaqItem from "../FaqItem";
import { dataFaq } from "../../data/data-faq/dataFaq";
const HowToBuySection = ({ img, dataUp, dataDown }) => {
  const { setIsOpen, isOpen } = useModal();

  const FaqComponent = ({ title, index, text }) => {
    return (
      <div className="faq_item">
        <div className="faq_eth">
          <img src={etherIcon} width={40} height={40} alt="" />
        </div>
        <div className="faq_bsc">
          <img src={bnbLogo} width={40} height={40} alt="" />
        </div>
        <div className="faq_title">
          {index}. {title}
        </div>
        <div className="faq_text">{text}</div>
      </div>
    );
  };
  return (
    <div className="how_to_buy_container" id="buy">
      <div className="howtobuy_mask"></div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <div className="faq_container">
        {dataFaq.map((item, id) => (
          <FaqComponent
            key={id}
            title={item.title}
            index={item.index}
            text={item.text}
          />
        ))}
      </div>
    </div>
  );
};

export default HowToBuySection;
