import React from "react";
import "./RoadmapCard.scss";
const RoadmapCard = ({ type, img, date, content, index }) => {
  return (
    <div className="road_map ">
      {type == true ? (
        <div className="roadmap_card">
          <div className="roadmap_content">
            <div className="roadmap-item">
              <span style={{ marginRight: "10px" }} className="roadmap_index">
                {index}
              </span>
              <div>
                <div className="roadmap-item__title">{date}</div>
                <div
                  style={{ whiteSpace: "pre-line" }}
                  className="roadmap-item__content"
                >
                  {content}
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap_img">
            <img
              style={{ zIndex: 99 }}
              src={img}
              width={220}
              height={220}
              alt=""
            />
          </div>
        </div>
      ) : (
        <div className="roadmap_card_reverse">
          <div className="roadmap_content_reverse">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                style={{ zIndex: 99 }}
                src={img}
                width={220}
                height={220}
                alt=""
              />
            </div>
          </div>
          <div className="roadmap_img_reverse">
            <div className="roadmap-item">
              {/* <div className="gif_file" style={{ position: "absolute", left: "-18px", top: "-18px" }}> */}
              <span style={{ marginRight: "10px" }} className="roadmap_index">
                {index}
              </span>

              {/* </div> */}
              <div>
                <div className="roadmap-item__title">{date}</div>
                <div
                  style={{ whiteSpace: "pre-line" }}
                  className="roadmap-item__content"
                >
                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoadmapCard;
