import "./Wellcome.scss";

import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  ethLogo,
  bnbLogo,
  solanaLogo,
  ethUsdtLogo,
  elephantLogo,
  homeLogoGif,
  etherIcon,
  RedCrownIcon,
  bscUsdtLogo,
} from "../../utils/allImgs";
import Modal from "../Modal";
import BuyModal from "../BuyModal";
import MoonLoader from "react-spinners/MoonLoader";
import { LuCopy } from "react-icons/lu";
import {
  useAppKitAccount,
  // useWalletInfo,
  useAppKitProvider,
} from "@reown/appkit/react";
import { ethers } from "ethers";
import {
  useDisconnect,
  useAppKit,
  useAppKitNetwork,
} from "@reown/appkit/react";
import { NotificationManager } from "react-notifications";

import ElumphantPresaleABI from "../../abi/ElumphantPresale.json";
import USDT_ABI_ETH from "../../abi/USDT_ETH.json";
import USDT_ABI_BSC from "../../abi/USDT_BSC.json";

import { networks, metadata, ethersAdapter } from "../../config";
import ClaimModal from "../ClaimModal";
import TextSlider from "../TextSlider";
import { useModal } from "../../ContextProvider";
import Timer from "../Timer";

const SecHeroSection = ({
  apiKey,
  ethContractAddress,
  bscContractAddress,
  bscUsdtContractAddress,
  ethUsdtContractAddress,
}) => {
  const { isOpen, setIsOpen } = useModal();

  const decimals = 18n;
  const divisor = 10n ** decimals; // 10^18

  const RPC_URL_ETH = `https://eth-mainnet.g.alchemy.com/v2/${apiKey}`;
  const RPC_URL_BSC = `https://bnb-mainnet.g.alchemy.com/v2/${apiKey}`;

  // const RPC_URL_ETH = `https://eth-sepolia.g.alchemy.com/v2/${apiKey}`
  // const RPC_URL_BSC = `https://bnb-testnet.g.alchemy.com/v2/${apiKey}`

  const [loading, setLoading] = useState(false);
  const [buyStatus, setBuyStatus] = useState(false);

  const [selectedNetwork, setSelectedNetwork] = useState(1);
  const [selectedCoin, setSelectedCoin] = useState(false);
  const [elumReceiveAmount, setElumReceiveAmount] = useState();
  // States for catching events
  const [buyEvent, setBuyEvent] = useState(false);
  const [changeElumpPriceEvent, setChangeElumpPriceEvent] = useState(false);

  const [period, setPeriod] = useState(false);
  // Memoize the setPeriod function
  const memoizedSetPeriod = useCallback((value) => {
    setPeriod(value);
  }, []);

  const { walletProvider } = useAppKitProvider("eip155");
  const [payAmount, setPayAmount] = useState("");
  const [purchasedAmount, setPurchasedAmount] = useState(0.0);
  const [elumPrice, setElumPrice] = useState(0);
  const [myBalance, setMyBalance] = useState(0);
  const [failedMessage, setFailedMessage] = useState("");
  // Native_ERC20

  const [progressBarStatus, setProgressBarStatus] = useState(0);
  // const [errorText, setErrorText] = useState("");
  const [inputError, setInputError] = useState("");
  const { address, caipAddress, isConnected, status } = useAppKitAccount();

  // const walletInfo = useWalletInfo();
  const { disconnect } = useDisconnect();
  const { open } = useAppKit();
  const { switchNetwork, chainId } = useAppKitNetwork();
  const [latestETHPrice, setLatestETHPrice] = useState(0);
  const [latestBSCPrice, setLaatestBSCPrice] = useState(0);
  const [leftAmount, setLeftAmount] = useState(0);

  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [copiedAddress, setCopiedAddress] = useState(false);

  function minimizeAddress(addr) {
    return addr.substring(0, 6) + "..." + addr.substring(addr.length - 4);
  }
  const formatNumber = (number) => {
    const [integerPart, decimalPart] = number.toString().split(".");
    const formattedInteger = parseInt(integerPart, 10).toLocaleString(); // Format the integer part with commas
    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };
  const createNotification = (type, buyer, amount) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(
          `Address ${buyer} bought ${Number(
            amount / 1000000000000000000n
          )} $Elump!`
        );
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Error message", "Click me!", 5000, () => {
          alert("callback");
        });
        break;
      default:
        break;
    }
  };
  // @@@
  const updateNetwork = useCallback(() => {
    if (chainId === 1) {
      setSelectedNetwork(1);
    } else if (chainId === 56) {
      setSelectedNetwork(2);
    }
  }, [chainId]);

  // Ensure the function is invoked when `chainId` changes
  useEffect(() => {
    updateNetwork();
  }, [updateNetwork]);
  // @@ Get total amount &&
  const getTotalElumpAmount = async () => {
    console.log("getTotalElumpAmount >>>");

    const provider = new ethers.JsonRpcProvider(RPC_URL_ETH);
    const contract = new ethers.Contract(
      ethContractAddress,
      ElumphantPresaleABI.abi,
      provider
    );

    const amount = await contract.totalTokensPurchased();
    const ethTotal = Number(amount / divisor);

    const bscProvider = new ethers.JsonRpcProvider(RPC_URL_BSC);
    const bscContract = new ethers.Contract(
      bscContractAddress,
      ElumphantPresaleABI.abi,
      bscProvider
    );

    const bscAmount = await bscContract.totalTokensPurchased();
    const bscTotal = Number(bscAmount / divisor);
    const total = ethTotal + bscTotal;
    const leftTokens = (total % 100000000) / 1000000;

    setProgressBarStatus(leftTokens);
    console.log("leftTokens >>>", leftTokens);
    setLeftAmount(100000000 - (total % 100000000));
    setBuyEvent(false);
  };
  useEffect(() => {
    getTotalElumpAmount();
  }, [buyEvent]);

  const isInitialized = useRef(false); // Flag to ensure `getTotal` is called only once
  // @@ Catch Buy Event - Notification @@

  const catchBuyEvent = async () => {
    const ethProvider = new ethers.JsonRpcProvider(RPC_URL_ETH);
    const bscProvider = new ethers.JsonRpcProvider(RPC_URL_BSC);
    console.log("catchBuyEvent >>>");
    try {
      const ethContract = new ethers.Contract(
        ethContractAddress,
        ElumphantPresaleABI.abi,
        ethProvider
      );
      const bscContract = new ethers.Contract(
        bscContractAddress,
        ElumphantPresaleABI.abi,
        bscProvider
      );
      // console.log("ethContract address: ", await ethContract.getAddress());
      // console.log("bscContract address: ", await bscContract.getAddress());
      ethContract.on("TokensPurchased", (buyer, amount) => {
        console.log("Amount>>> ", amount);
        if (amount <= 0) return;
        console.log("ETH TokensPurchased >>>");
        console.log(`Address ${buyer} bought ${amount} of tokens!`);
        setBuyEvent(!buyEvent);
        createNotification("success", minimizeAddress(buyer), amount);
      });
      bscContract.on("TokensPurchased", (buyer, amount) => {
        if (amount <= 0) return;
        console.log("BSC TokensPurchased >>>");
        console.log(`Address ${buyer} bought ${amount} of tokens!`);
        setBuyEvent(!buyEvent);
        createNotification("success", minimizeAddress(buyer), amount);
      });

      // Get Event whenever price is changed manually
      ethContract.on("TokenPriceUpdated", (oldPrice, newPrice) => {
        console.log("Price >>>", oldPrice, newPrice);
        if (oldPrice === newPrice) {
          console.log("Price did not change.");
          return;
        }
        setChangeElumpPriceEvent(!changeElumpPriceEvent);
        console.log(`Elump Price changed from ${oldPrice} to ${newPrice}!`);
      });
      bscContract.on("TokenPriceUpdated", (oldPrice, newPrice) => {
        if (oldPrice === newPrice) {
          console.log("Price did not change.");
          return;
        }
        console.log(`BSC Elump Price changed from ${oldPrice} to ${newPrice}!`);
        setChangeElumpPriceEvent(!changeElumpPriceEvent);
      });
    } catch (error) {
      console.log("catchBuyEvent Error: ", error);
    }
  };

  useEffect(() => {
    // catchBuyEvent(); // Call only once
    if (!isInitialized.current) {
      isInitialized.current = true; // Mark as initialized
      catchBuyEvent(); // Call only once
    }
  }, []);

  // Disconnect Handler
  const disconnectHandler = async () => {
    try {
      await disconnect();
      setInputError("");
      setFailedMessage("");
      setPayAmount("");
      setElumReceiveAmount("");
    } catch (error) {
      console.log("Disconnect Error: ", error);
    }
  };
  // @ Get Price of $Elum
  const getPriceHandler = async () => {
    console.log("getPriceHandler >>>", changeElumpPriceEvent);
    // if (changeElumpPriceEvent === false) return;
    // Create a provider (e.g., using ethers.js with a specific network or provider URL)
    if (selectedNetwork === 1) {
      const provider = new ethers.JsonRpcProvider(RPC_URL_ETH);

      // Initialize the contract with the provider
      const contract = new ethers.Contract(
        ethContractAddress,
        ElumphantPresaleABI.abi,
        provider
      );
      const price = await contract.tokenPriceInUSD();

      const latestEth = await contract.getLatestETHPrice();
      // const test = ethers.formatEther(latestEth);
      setLatestETHPrice(Number(latestEth) / 100000000);
      setElumPrice(Number(price) / 1000000);
      setChangeElumpPriceEvent(false);
      // Call the function
    } else if (selectedNetwork === 2) {
      const provider = new ethers.JsonRpcProvider(RPC_URL_BSC);

      // Initialize the contract with the provider
      const contract = new ethers.Contract(
        bscContractAddress,
        ElumphantPresaleABI.abi,
        provider
      );
      const price = await contract.tokenPriceInUSD();
      const latestBSC = await contract.getLatestETHPrice();
      setElumPrice(Number(price) / 1000000000000000000);
      setLaatestBSCPrice(Number(latestBSC) / 100000000);
      setChangeElumpPriceEvent(false);
      console.log("PriceBSC: ", price);
      // Call the function
    }
  };

  useEffect(() => {
    getPriceHandler();
  }, [chainId, changeElumpPriceEvent, isConnected]);

  // @ Swith Handler
  const switchNetworkHandler = async () => {
    console.log("switchNetworkHandler >>>");
    setInputError("");
    try {
      if (selectedNetwork === 2) {
        setSelectedCoin(false);
        switchNetwork(networks[0]);
        setSelectedNetwork(1);
        setSelectedCoin(false);
        setElumReceiveAmount("");
        setPayAmount("");
      } else {
        console.log("changing to network 2");
        setSelectedCoin(false);
        switchNetwork(networks[3]);
        setSelectedNetwork(2);
        setSelectedCoin(false);
        setElumReceiveAmount("");
        setPayAmount("");
      }
    } catch (error) {
      console.log("Switch Network Error: ", error);
      return;
    }
  };
  // @@ ETH -> USDT Handler @@
  const ethHandler = async () => {
    setInputError("");
    setSelectedCoin(false);
    setPayAmount("");
    setElumReceiveAmount("");
    if (selectedNetwork === 1) {
    }
  };
  const usdtHandler = () => {
    setInputError("");
    setSelectedCoin(true);
    setPayAmount("");
    setElumReceiveAmount("");
  };

  // @ Get Purchased Amount
  const getPurchasedHandler = async () => {
    console.log("getPurchasedHandler>>>");
    if (isConnected === false) return;
    const ethProvider = new ethers.JsonRpcProvider(RPC_URL_ETH);
    const bscProvider = new ethers.JsonRpcProvider(RPC_URL_BSC);
    const ethContract = new ethers.Contract(
      ethContractAddress,
      ElumphantPresaleABI.abi,
      ethProvider
    );
    const bscContract = new ethers.Contract(
      bscContractAddress,
      ElumphantPresaleABI.abi,
      bscProvider
    );

    // Initialize the contract with the provider
    const purchasedOnETH = await ethContract.purchasedTokens(address);
    const purchasedOnBSC = await bscContract.purchasedTokens(address);
    const totalPurchased = purchasedOnETH + purchasedOnBSC;

    setPurchasedAmount(Number(totalPurchased / divisor));
    setBuyEvent(false);
  };
  useEffect(() => {
    if (isConnected === false) {
      return;
    } else {
      getPurchasedHandler();
    }
  }, [chainId, isConnected, buyEvent]);

  // @ Get my wallet balance
  const getMyWalletBalance = async (status) => {
    console.log("getMyWalletBalance>>>", buyEvent);

    try {
      if (selectedCoin === false) {
        const provider = new ethers.BrowserProvider(walletProvider);
        const balanceWei = await provider.getBalance(address);
        const balanceEther = ethers.formatEther(balanceWei);
        const amount = parseFloat(balanceEther).toFixed(5).toString();
        setMyBalance(amount);
        // setPayAmount(amount.toString());
      } else {
        const provider = new ethers.BrowserProvider(walletProvider);
        if (selectedNetwork === 1) {
          const usdtContract = new ethers.Contract(
            ethUsdtContractAddress,
            USDT_ABI_ETH.abi,
            provider
          );

          const usdtAmount = await usdtContract.balanceOf(address);
          const usdtBalance = (Number(usdtAmount) / 1000000).toFixed(5);
          setMyBalance(usdtBalance);
          // setPayAmount(usdtBalance.toString())
        } else {
          const usdtContract = new ethers.Contract(
            bscUsdtContractAddress,
            USDT_ABI_BSC.abi,
            provider
          );

          const usdtAmount = await usdtContract.balanceOf(address);
          const usdtBalance = (
            Number(usdtAmount) / 1000000000000000000
          ).toFixed(5);
          setMyBalance(usdtBalance);
          // setPayAmount(usdtBalance.toString())
        }
      }
    } catch (error) {
      console.log("GetMyWalletBalance Error: ", error);
      return;
    }
  };
  useEffect(() => {
    if (isConnected) getMyWalletBalance();
  }, [selectedCoin, chainId, walletProvider, buyStatus, address]);
  // @ Max Handler
  const onMaxHandler = async () => {
    console.log("onMaxHandler>>>");

    setPayAmount(myBalance.toString());
    console.log("Test>>>", latestETHPrice, myBalance, elumPrice);
    if (selectedCoin === false) {
      setElumReceiveAmount(
        ((myBalance * latestETHPrice) / elumPrice).toFixed(2)
      );
    } else {
      setPayAmount(myBalance);
      setElumReceiveAmount(myBalance / elumPrice);
    }
  };
  // @@@ Modal Handler
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const colseBuyModal = () => {
    setBuyStatus(false);
  };
  const closeClaimModalHandler = () => {
    setOpenClaimModal(false);
  };

  // @@@ Connect Wallet Handler @@@@
  const connectWalletHandler = async () => {
    // if (inputError !== "") return;
    console.log("connectWalletHandler>>>");
    // Connect Wallet
    if (isConnected === false) {
      try {
        await open();
        setPayAmount("");
        setElumReceiveAmount("");
        setInputError("");
      } catch (error) {
        return;
      }
    }
    // After Connecting wallet
    else {
      if (!window.ethereum) {
        alert("Please install MetaMask!");
        return;
      }
      setLoading(true);

      // @@@@@ Check the presale time @@@@@
      if (period === false) {
        handleOpen();
        setLoading(false);
        return;
      }

      if (parseFloat(payAmount) <= 0 || payAmount === "") {
        setInputError("The amount should over than 0");
        setLoading(false);
        return;
      }

      if (selectedNetwork === 1) {
        try {
          // Connect to Ethereum provider (MetaMask)
          const provider = new ethers.BrowserProvider(walletProvider);
          const signer = await provider.getSigner();
          if (selectedCoin === false) {
            if (parseFloat(payAmount) * latestETHPrice < 10) {
              setInputError("Amount less than minimum $10");
              setLoading(false);
              return;
            }
            // Create a contract instance using the imported ABI
            const contract = new ethers.Contract(
              ethContractAddress,
              ElumphantPresaleABI.abi,
              signer
            );
            // Specify the ETH amount to send (e.g., 0.1 ETH)
            const ethAmount = ethers.parseEther(payAmount); // Convert ETH to Wei
            // Call the buyWithETH function
            const tx = await contract.buyWithETH({ value: ethAmount });
            // Wait for the transaction to be mined
            const receipt = await tx.wait();
            setPayAmount("");
            setElumReceiveAmount("");
            setBuyEvent(false);
            setBuyStatus(true);
            console.log("Transaction mined:", receipt);
          } else if (selectedCoin === true) {
            if (payAmount < 10) {
              setLoading(false);
              setInputError("Amount less than minimum $10");
              return;
            } else if (parseFloat(payAmount) > parseFloat(myBalance)) {
              setLoading(false);
              setFailedMessage("");
              setInputError("Your balance is insufficient");
              return;
            }

            const usdtContract = new ethers.Contract(
              ethUsdtContractAddress,
              USDT_ABI_ETH.abi,
              signer
            );
            const usdtAmount = ethers.parseUnits(payAmount, 6);

            // Get the current approval
            const curApproved = await usdtContract.allowance(
              signer.address,
              ethContractAddress
            );
            console.log("curApproved: ", curApproved);

            if (curApproved < usdtAmount) {
              if (curApproved > 0) {
                // If the current approval is not enough, revoke it first.
                const approveTx = await usdtContract.approve(
                  ethContractAddress,
                  0
                );
                await approveTx.wait();
              }

              // Request a new approval.
              const approveTx = await usdtContract.approve(
                ethContractAddress,
                usdtAmount
              );
              await approveTx.wait();
            } else console.log("No need for an additional approval!");

            const presaleContract = new ethers.Contract(
              ethContractAddress,
              ElumphantPresaleABI.abi,
              signer
            );
            const tx = await presaleContract.buyWithUSDT(usdtAmount);
            const receipt = await tx.wait();
            console.log("Transaction mined:", receipt);
            setBuyStatus(true);
            setPayAmount("");
            setElumReceiveAmount("");
            setBuyEvent(false);
          }
        } catch (error) {
          inputError === "" &&
            setFailedMessage(
              "The request could not be processed. Please try again."
            );
          console.error("Error calling buying:", error.message);
          setBuyStatus(false);
          setLoading(false);
          if (
            error.message
              .toLowerCase()
              .includes("amount less than minimum limit")
          ) {
            setLoading(false);
            setInputError("Amount less than minimum limit - $10");
          } else if (
            error.message.toLowerCase().includes("insufficient funds")
          ) {
            // alert("Minimum purchase is $10");
            setFailedMessage("");
            setInputError("Your balance is insufficient");
            setLoading(false);
          }
        }
      } else if (selectedNetwork === 2) {
        try {
          // Connect to Ethereum provider (MetaMask)
          const provider = new ethers.BrowserProvider(walletProvider);
          const signer = await provider.getSigner();
          if (selectedCoin === false) {
            if (parseFloat(payAmount) * latestBSCPrice < 10) {
              setInputError("Amount less than minimum $10");
              setLoading(false);
              return;
            }
            // Create a contract instance using the imported ABI
            const contract = new ethers.Contract(
              bscContractAddress,
              ElumphantPresaleABI.abi,
              signer
            );
            // Specify the ETH amount to send (e.g., 0.1 ETH)
            const ethAmount = ethers.parseEther(payAmount); // Convert ETH to Wei
            // Call the buyWithETH function
            const tx = await contract.buyWithETH({ value: ethAmount });
            // Wait for the transaction to be mined
            const receipt = await tx.wait();
            setBuyStatus(true);
            setPayAmount("");
            setElumReceiveAmount("");
            setBuyEvent(false);
            console.log("Transaction mined:", receipt);
          } else if (selectedCoin === true) {
            if (payAmount < 10) {
              setLoading(false);
              setInputError("Minimum purchase is $10");
              return;
            } else if (parseFloat(payAmount) > parseFloat(myBalance)) {
              setLoading(false);
              setFailedMessage("");
              setInputError("Your balance is insufficient");
              return;
            }

            const usdtContract = new ethers.Contract(
              bscUsdtContractAddress,
              USDT_ABI_BSC.abi,
              signer
            );
            const usdtAmount = ethers.parseUnits(payAmount, 18);

            const curApproved = await usdtContract.allowance(
              signer.address,
              bscContractAddress
            );
            console.log("curApproved: ", curApproved);

            if (curApproved < usdtAmount) {
              // Request a new approval.
              const approveTx = await usdtContract.approve(
                bscContractAddress,
                usdtAmount
              );
              await approveTx.wait();
            } else console.log("No need for an additional approval!");

            const presaleContract = new ethers.Contract(
              bscContractAddress,
              ElumphantPresaleABI.abi,
              signer
            );
            const tx = await presaleContract.buyWithUSDT(usdtAmount);
            const receipt = await tx.wait();
            setBuyEvent(false);
            setBuyStatus(true);
            setPayAmount("");
            setElumReceiveAmount("");
            console.log("Transaction mined:", receipt);
          }
        } catch (error) {
          console.error("Error calling buying:", error.message);
          inputError === "" &&
            setFailedMessage(
              "The request could not be processed. Please try again."
            );
          setBuyStatus(false);
          setLoading(false);
          if (error.message.toLowerCase().includes("missing revert")) {
            setFailedMessage("");
            setInputError("Your balance is insufficient");
          } else if (
            error.message
              .toLowerCase()
              .includes("amount less than minimum limit")
          ) {
            setInputError("Minimum purchase is $10");
          }
        }
      }
      setLoading(false);
    }
  };
  // @ Change Handler in Buy Section
  const onChangeHandler = (e) => {
    // if (isConnected === false) return;

    console.log("onChangeHandler>>>");
    setFailedMessage("");
    const value = e.target.value;
    if (parseFloat(value) >= 0) {
      setInputError("");
    }
    if (value === "" || value === 0) {
      setInputError("");
    }

    if (selectedCoin === false) {
      if (selectedNetwork === 1 && parseFloat(value) * latestETHPrice < 10) {
        setInputError("The minimum amount is $10");
      } else if (selectedNetwork === 1 && parseFloat(value) > myBalance) {
        setInputError("Your balance is insufficient");
      } else if (
        selectedNetwork === 2 &&
        parseFloat(value) * latestBSCPrice < 10
      ) {
        setInputError("The minimum amount is $10");
      } else if (selectedNetwork === 2 && parseFloat(value) > myBalance) {
        setInputError("Your balance is insufficient");
      }
    } else {
      if (selectedNetwork === 1 && parseFloat(value) < 10) {
        setInputError("The minimum amount is $10");
      } else if (selectedNetwork === 1 && parseFloat(value) > myBalance) {
        setInputError("Your balance is insufficient");
      } else if (selectedNetwork === 2 && parseFloat(value) < 10) {
        setInputError("The minimum amount is $10");
      } else if (selectedNetwork === 2 && parseFloat(value) > myBalance) {
        setInputError("Your balance is insufficient");
      }
    }
    setPayAmount(value);
    if (selectedCoin === false) {
      if (selectedNetwork === 1) {
        setElumReceiveAmount(((value * latestETHPrice) / elumPrice).toFixed(2));
      } else {
        setElumReceiveAmount(((value * latestBSCPrice) / elumPrice).toFixed(2));
      }
    } else {
      setElumReceiveAmount((value / elumPrice).toFixed(2));
    }
  };
  const onChangeElumpHandler = (e) => {
    const value = e.target.value;
    // if (isConnected === false) return;
    console.log("Value >>>", typeof value);

    setInputError("");
    setElumReceiveAmount(value);

    if (selectedCoin === false) {
      if (selectedNetwork === 1) {
        const payWithValue = (value * elumPrice) / latestETHPrice;
        if (value * elumPrice < 10) {
          setInputError("The minimum amount is $10");
        } else if (value * elumPrice > myBalance * latestETHPrice) {
          setInputError("Your balance is insufficient");
        }
        setPayAmount(payWithValue.toFixed(6));
      } else {
        const payWithValue = (value * elumPrice) / latestBSCPrice;
        if (value * elumPrice < 10) {
          setInputError("The minimum amount is $10");
        } else if (value * elumPrice > myBalance * latestETHPrice) {
          setInputError("Your balance is insufficient");
        }
        setPayAmount(payWithValue.toFixed(6));
      }
    } else {
      const payWithValue = value * elumPrice;
      if (payWithValue < 10) {
        setInputError("The minimum amount is $10");
      } else if (payWithValue > myBalance) {
        setInputError("Your balance is insufficient");
      }
      setPayAmount(payWithValue.toFixed(4));
    }

    if (value === "") {
      console.log("Undefined >>>");
      setInputError("");
      setPayAmount("");
    } else if (value === "0") {
      console.log("Nan >>>");
      setInputError("");
      setPayAmount("");
    }
  };

  const onCliamHandler = async () => {
    setOpenClaimModal(true);
  };
  const copyAddressHandler = async () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setCopiedAddress(true); // Set copied state to true on success
        setTimeout(() => setCopiedAddress(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  /* DOM */
  return (
    <div className="hero_section_content" id="home">
      <div className="hero_container">
        <div className="hero_left">
          <Modal isOpen={isOpen} onClose={handleClose} />
          <BuyModal isOpen={buyStatus} onClose={colseBuyModal} />
          <ClaimModal
            isOpen={openClaimModal}
            onClose={closeClaimModalHandler}
          />
          {/* Buy Section - Start */}
          <div className="buy-section">
            {period === false ? (
              <div>
                <img src={RedCrownIcon} width={28} height={28} alt="" />
              </div>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img src={RedCrownIcon} width={28} height={28} alt="" />
                <div
                  style={{
                    color: "gold",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Road to Triumph: The Countdown to Jan 20th
                </div>
                <img src={RedCrownIcon} width={28} height={28} alt="" />
              </div>
            )}

            <h6
              className="funny_buy_font"
              style={{
                marginBottom: 0,
                fontFamily: "'Shantell Sans', sans-serif",
              }}
            >
              Presale {period === true ? "Ends" : "Starts"} in
            </h6>
            <Timer setPeriod={memoizedSetPeriod} />
            {period === true && (
              <div className="progress_text">
                $Elump price rises after{" "}
                <span style={{ color: "gold" }}>
                  {formatNumber(leftAmount)}{" "}
                </span>
                tokens sold. Join the early ride!
              </div>
            )}

            {period === true && (
              <div style={{ width: "70%" }} className="bar_container">
                <div
                  className="bar_content"
                  style={{ width: `${progressBarStatus}%` }}
                ></div>
              </div>
            )}

            <h6
              className="funny_buy_font"
              style={{ paddingTop: "10px", marginBottom: "-5px" }}
            >
              Upcoming Tokens
            </h6>
            {purchasedAmount > 0 && (
              <div
                className="switch_network"
                style={{ marginTop: "-10px !important" }}
              >
                <div
                  style={{ marginTop: "0px !important" }}
                  className="switch_sol"
                  onClick={onCliamHandler}
                >
                  <div style={{ fontSize: "14px" }}>Claim $Elump</div>
                </div>
              </div>
            )}
            <div
              style={{ paddingTop: "7px" }}
              className="detail_text funny_font"
            >
              Your purchased $Elump:{" "}
              {isConnected === true ? formatNumber(purchasedAmount) : 0}{" "}
            </div>
            <div className="detail_text funny_font text-center">
              Your staking $Elump: 0<br /> {}{" "}
            </div>
            <div className="price_elump">
              <div className="white_line"></div>
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                className="funny_font"
              >
                <div>1 $Elump = ${elumPrice}</div>
              </div>
              <div className="white_line"></div>
            </div>
            <div className="selected_crypto">
              <div
                className={`${
                  selectedCoin === true
                    ? "main_network"
                    : "main_network__selected"
                }`}
                onClick={ethHandler}
              >
                <img
                  src={
                    selectedNetwork === 1
                      ? ethLogo
                      : selectedNetwork === 2
                      ? bnbLogo
                      : solanaLogo
                  }
                  width={20}
                  height={20}
                  alt=""
                />
                <span style={{ fontSize: "14px", marginLeft: "6px" }}>
                  {selectedNetwork === 1
                    ? "ETH"
                    : selectedNetwork === 2
                    ? "BNB"
                    : "Solana"}
                </span>
              </div>
              <div
                className={`${
                  selectedCoin === false
                    ? "usdt_network"
                    : "usdt_network__selected"
                }`}
                onClick={usdtHandler}
              >
                <img
                  src={selectedNetwork === 1 ? ethUsdtLogo : bscUsdtLogo}
                  width={24}
                  height={24}
                  alt=""
                />
                <span style={{ fontSize: "14px", marginLeft: "6px" }}>
                  USDT
                </span>
              </div>
            </div>
            <div className="network_value">
              <div className="network_send">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    paddingRight: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div className="inpu_send_detail">
                      Pay with{" "}
                      {selectedCoin === true
                        ? "USDT"
                        : selectedNetwork === 1
                        ? "ETH"
                        : selectedNetwork === 2
                        ? "BNB"
                        : "Solana"}
                    </div>
                    <span>
                      {payAmount === "0" ? (
                        <div className="my_wallet_balance">$0</div>
                      ) : payAmount === "0" ? (
                        ""
                      ) : (
                        <div className="my_wallet_balance">
                          $
                          {payAmount === ""
                            ? 0
                            : selectedCoin === false
                            ? selectedNetwork === 1
                              ? formatNumber(
                                  (
                                    latestETHPrice * parseFloat(payAmount)
                                  ).toFixed(2)
                                )
                              : formatNumber(
                                  (
                                    latestBSCPrice * parseFloat(payAmount)
                                  ).toFixed(2)
                                )
                            : formatNumber(parseFloat(payAmount))}
                        </div>
                      )}
                    </span>
                  </div>
                  <div
                    onClick={onMaxHandler}
                    className="max_btn"
                    style={{
                      color: "gold",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Max
                  </div>
                </div>

                <div className="send_input">
                  <input
                    type="text"
                    value={payAmount}
                    onChange={onChangeHandler}
                    placeholder="0"
                    required
                  />
                  <div className="network_icon">
                    <img
                      src={
                        selectedCoin === true
                          ? selectedNetwork === 1
                            ? ethUsdtLogo
                            : bscUsdtLogo
                          : selectedNetwork === 1
                          ? ethLogo
                          : selectedNetwork === 2
                          ? bnbLogo
                          : solanaLogo
                      }
                      width={selectedNetwork === 1 ? 24 : 22}
                      height={selectedCoin === 1 ? 24 : 22}
                      alt=""
                    />
                  </div>
                </div>
                {isConnected === true && (
                  <div className="valid_input">{inputError}</div>
                )}
              </div>
              <div className="network_receive">
                <div className="input_detail hide-spinner">
                  $Elump you receive
                </div>
                <div className="receive_input">
                  <input
                    type="number"
                    className="hide-spinner"
                    value={elumReceiveAmount}
                    placeholder="0"
                    onChange={onChangeElumpHandler}
                    required
                  />
                  <div className="network_icon">
                    <img src={elephantLogo} width={24} height={24} alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* Get My Wallet Balance Display - Start */}
            {isConnected === true && (
              <div className="my_wallet_info">
                <span style={{ color: "white" }}>Your Balance: </span>{" "}
                {selectedCoin === false ? (
                  selectedNetwork === 1 ? (
                    <div>
                      <img src={etherIcon} width={20} height={20} alt="" />{" "}
                      <span>
                        {parseFloat(myBalance) == 0
                          ? "0"
                          : formatNumber(myBalance)}
                        <span style={{ color: "white" }}> ETH</span>
                      </span>
                    </div>
                  ) : (
                    <div>
                      <img src={bnbLogo} width={20} height={20} alt="" />{" "}
                      <span>
                        {parseFloat(myBalance) == 0
                          ? "0"
                          : formatNumber(myBalance)}
                        <span style={{ color: "white" }}> BNB</span>
                      </span>
                    </div>
                  )
                ) : selectedNetwork === 1 ? (
                  <div>
                    <img src={ethUsdtLogo} width={20} height={20} alt="" />{" "}
                    <span>
                      {parseFloat(myBalance) == 0
                        ? "0"
                        : formatNumber(myBalance)}
                      <span style={{ color: "white" }}> USDT</span>
                    </span>
                  </div>
                ) : (
                  <div>
                    <img src={bscUsdtLogo} width={20} height={20} alt="" />{" "}
                    <span>
                      {parseFloat(myBalance) == 0
                        ? "0"
                        : formatNumber(myBalance)}
                      <span style={{ color: "white" }}> USDT</span>
                    </span>
                  </div>
                )}
              </div>
            )}
            {isConnected === true && (
              <div className="address_title">
                Your Address:{" "}
                <span onClick={copyAddressHandler} className="address_text">
                  {minimizeAddress(address)}
                </span>
                <span onClick={copyAddressHandler} className="copy_icon">
                  <LuCopy color={copiedAddress ? "gold" : "white"} />
                </span>
              </div>
            )}
            {/* Get My Wallet Balance Display - End */}

            {/* {isConnected === true && <div style={{ color: "white", paddingTop: "10px" }}><appkit-button color="red" /></div>} */}
            <div
              style={{ paddingTop: "5px", fontSize: "16px" }}
              className="valid_input"
            >
              {failedMessage}
            </div>
            <div
              onClick={() => {
                loading === false && connectWalletHandler();
              }}
              className={
                isConnected === false || inputError === ""
                  ? "buy_button"
                  : "buy_button_disabled"
              }
              // className="buy_button"
            >
              {isConnected === false ? (
                "Connect Wallet"
              ) : loading === false ? (
                "Buy"
              ) : (
                <div>
                  <MoonLoader
                    color="#002347" // Color of the spinner
                    size={24}
                    loading={loading} // Boolean to show/hide loader
                    height={5} // Height of each bar
                    width={5} // Width of each bar
                    radius={1} // Radius of bar edges
                    speedMultiplier={0.5} // Adjust the speed of the animation
                  />
                </div>
              )}
            </div>

            <div className="switch_network">
              <div className="switch_bnb" onClick={switchNetworkHandler}>
                <div>Switch To {selectedNetwork === 2 ? "ETH" : "BNB"}</div>
                <img
                  src={selectedNetwork === 2 ? ethLogo : bnbLogo}
                  width={20}
                  height={20}
                  alt=""
                />
              </div>
              {/* <div className="switch_sol" onClick={switchSolanaHandler}>
                    <div>Switch To {selectedNetwork === 3 ? "ETH" : "Solana"}</div>
                    <img src={selectedNetwork === 3 ? ethLogo : solanaLogo} width={20} height={20} alt="" />
                  </div> */}
            </div>
            {isConnected === true && (
              <div className="switch_network">
                <div className="switch_sol" onClick={disconnectHandler}>
                  <div>Disconnect</div>
                </div>
              </div>
            )}
          </div>
          {/* Buy Section - End */}
        </div>
        <div className="elephantLogo">
          <TextSlider />
          <img draggable="false" width={"85%"} src={homeLogoGif} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SecHeroSection;
