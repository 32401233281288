import React, { useState, useEffect, useRef } from "react";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";

import "./SecHeader.scss";
import Marquee from "react-fast-marquee";
import {
  YellowLogoImg,
} from "../../../utils/allImgs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useModal } from "../../../ContextProvider";
import { useHistory } from 'react-router-dom';

const SecHeader = ({ Logo }) => {
  const { setIsOpen } = useModal();
  const history = useHistory();
  const navbarRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); const handleClick = (e, target) => {
    e.preventDefault();
    // Navigate to the new page
    history.push('/');

    // Scroll to the section after the page is rendered
    setTimeout(() => {
      const element = document.querySelector(target);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200); // Timeout to allow the page to load first
  };
  // 
  const handleOutsideClick = (e) => {
    const collapsibleNavbar = document.getElementById("collapsibleNavbar");

    // Close the navbar if the click is outside of it
    if (navbarRef.current && !navbarRef.current.contains(e.target)) {
      if (collapsibleNavbar && collapsibleNavbar.classList.contains("show")) {
        // Trigger Bootstrap's collapse functionality by clicking the navbar toggle button
        const navbarToggleButton = document.querySelector(".navbar-toggler");
        if (navbarToggleButton) {
          navbarToggleButton.click(); // Simulate a click to close the navbar
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <nav
      style={{ display: "flex", flexDirection: "column" }}
      className="fixed-top"
      id="banner"
      ref={navbarRef}
    >
      <div className="container_header navbar navbar-expand-lg navbar-white">
        {/* Brand */}
        <a className="navbar-brand" href="/#">
          <span>
            <img src={YellowLogoImg} width={200} height={100} alt="logo" />
          </span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
          aria-expanded="false"
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Navbar links */}
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a
                className="nav-link"
                href="/#home"
                onClick={(e) => handleClick(e, '#home')}
                data-bs-toggle="collapse"
                data-bs-target={windowWidth < 768 && "#collapsibleNavbar"}
                aria-expanded="false"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/#about"
                onClick={(e) => handleClick(e, '#about')}
                data-bs-toggle="collapse"
                data-bs-target={windowWidth < 768 && "#collapsibleNavbar"}
                aria-expanded="false"
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/how-to-buy">
                How to Buy
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="/#whitepaper"
                onClick={(e) => handleClick(e, '#whitepaper')}
                data-bs-target={windowWidth < 768 && "#collapsibleNavbar"}
                data-bs-toggle="collapse"
                aria-expanded="false"
              >
                WhitePaper
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                href="/#roadmap"
                onClick={(e) => handleClick(e, '#roadmap')}
                data-bs-toggle="collapse"
                data-bs-target={windowWidth < 768 && "#collapsibleNavbar"}
                aria-expanded="false"
              >
                Roadmap
              </a>
            </li>
            <li
              className="nav-item"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsOpen(true);
                const navbarCollapse = document.getElementById('collapsibleNavbar');
                if (navbarCollapse.classList.contains('show')) {
                  navbarCollapse.classList.remove('show');
                }
              }}
            >
              <div className="nav-link">Staking</div>
            </li>
            {/*  */}
            <li className="social_btns">
              <div
                className="social_btn"
                id="socialBtn"
                onClick={() => {
                  window.open("https://x.com/elumphant", "_blank");
                  const navbarCollapse = document.getElementById('collapsibleNavbar');
                  if (navbarCollapse.classList.contains('show')) {
                    navbarCollapse.classList.remove('show');
                  }
                }}
              >
                <RiTwitterXFill size={16} />
              </div>
              <div
                className="social_btn"
                id="TelegramBtn"
                onClick={() => {
                  window.open("https://t.me/elumphant_public", "_blank");
                  const navbarCollapse = document.getElementById('collapsibleNavbar');
                  if (navbarCollapse.classList.contains('show')) {
                    navbarCollapse.classList.remove('show');
                  }
                }}
              >
                <FaTelegramPlane color={""} size={16} />
              </div>
              <div className="header_buy_btn" id="header_buy_btn">
                <a
                  href="/#home"
                  onClick={(e) => {
                    // Prevent the default behavior of the anchor tag (navigation)
                    e.preventDefault();

                    // Scroll to the section (this simulates the href behavior)
                    document.querySelector('#home').scrollIntoView({
                      behavior: 'smooth', // Smooth scroll
                    });

                    // Toggle collapse by closing the navbar
                    const navbarCollapse = document.getElementById('collapsibleNavbar');
                    if (navbarCollapse.classList.contains('show')) {
                      navbarCollapse.classList.remove('show');
                    }
                  }}
                >
                  Buy $Elump
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/*  */}
      <div className="breaking_news">
        <Marquee
          gradientColor="#a2202c"
          gradient={false}
          style={{
            gap: "10px",
            animation: "backgroundColorChange 5s infinite", // Add animation
            padding: "5px 5px",
            fontSize: "16px",
            width: "100%",
            margin: "0px",
          }}
        >
          <span
            style={{
              animation: "textColorChange 5s infinite", // Text color animation
            }}
          >
            Breaking News: $Elump is here to conquer the meme-verse! Ride the
            bullish beast with Elon-level ambition, Trump-style boldness, and
            unbeatable staking rewards. Get in now—don’t miss the moonshot!
            🚀🐘🔥
          </span>
          <style>
            {`
                /* Background color transition */
                @keyframes backgroundColorChange {
                  0% { background-color: #ff3854; } /* Start with red */
                  50% { background-color: gold; } /* Transition to yellow */
                  100% { background-color: #ff3854; } /* Start with red */
                }
                /* Text color transition */
                @keyframes textColorChange {
                  0% { color: black; } /* Red */
                  50% { color: #ff3854; } /* Yellow */
                  100% { color: black; } /* Green */
                }
                `}
          </style>
        </Marquee>
      </div>
    </nav>
  );
};

export default SecHeader;
