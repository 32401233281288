import { useEffect, useState } from "react";
import { addRemoveClassBody } from "../../utils";
import { HowToStartInfo } from "../../data/data-containers/HomeDemo2/data-HomeDemo2.js";
import axios from "axios";

import RoadmapInfo from "../../data/data-containers/HomeDemo2/data-RoadmapInfo.json";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { createAppKit } from "@reown/appkit/react";
import { networks, projectId, metadata, ethersAdapter } from "../../config";
// import ActionButtonList from '../../components/ActionButtonList';
// import InfoList from '../../components/InfoList';

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";

import SecHeroSection from "../../components/HeroSection";

import Roadmap from "../../components/Roadmap";
import WhitePaper from "../../components/WhitePaper/index.jsx";
import HowToStart from "../../components/HowToStart";
import Modal from "../../components/Modal/";
import { useModal } from "../../ContextProvider.js";

const Main = () => {
  const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
  const ethContractAddress = process.env.REACT_APP_ETH_CONTRACT;
  const bscContractAddress = process.env.REACT_APP_BSC_CONTRACT;
  const ethUsdtContractAddress = process.env.REACT_APP_ETH_USDT_CONTRACT;
  const bscUsdtContractAddress = process.env.REACT_APP_BSC_USDT_CONTRACT;
  const { isOpen, setIsOpen } = useModal();

  createAppKit({
    adapters: [ethersAdapter],
    networks,
    metadata,
    projectId,
    features: {
      analytics: true, // Optional - defaults to your Cloud configuration
      socials: false,
      email: false,
      emailShowWallets: true,
    },
    // allWallets: 'HIDE',
  });
  function minimizeAddress(addr) {
    return addr.substring(0, 6) + "..." + addr.substring(addr.length - 4);
  }
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  const [error, setError] = useState("");
  // const [isOpen, setIsOpen] = useState(false);

  const fetchWallet = async () => {
    try {
      console.log("fetchWallet >>>");
      const response = await axios.get(
        "https://elumphant-backend.onrender.com/api/wallets"
      );

      if (!response?.data?.buyer || !response?.data?.amount) {
        return;
      }
      console.log("Res >>>", response?.data.buyer);
      createNotification(
        "success",
        minimizeAddress(response?.data.buyer),
        response?.data?.amount
      );
    } catch (err) {
      setError("Failed to fetch wallet data");
      console.error("Error fetching wallet:", err);
    }
  };

  const createNotification = (type, buyerAddress, buyerAmount) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(
          `Address ${buyerAddress} bought ${buyerAmount} of tokens!`,
          "Success"
        );
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Error message", "Click me!", 5000, () => {
          alert("callback");
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Fetch wallet data initially
    fetchWallet();
    // Set an interval to fetch wallet data every 5 seconds
    const interval = setInterval(fetchWallet, 3000);
    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <NotificationContainer />
      <Header Title="Elumphant" isOpen={isOpen} setIsOpen={setIsOpen} />
      <SecHeroSection
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        apiKey={apiKey}
        ethContractAddress={ethContractAddress}
        bscContractAddress={bscContractAddress}
        ethUsdtContractAddress={ethUsdtContractAddress}
        bscUsdtContractAddress={bscUsdtContractAddress}
      />
      <HowToStart data={HowToStartInfo} setIsOpen={setIsOpen} isOpen={isOpen} />
      <WhitePaper />
      <Roadmap data={RoadmapInfo} />
      <Footer />
    </div>
  );
};

export default Main;
