// import { roadmapImg2, roadmapImg1, roadmapImg3, roadmapImg4 } from "../../utils/allImgs";

export const dataFaq = [
    {
        title: "Create Your Wallet",
        index: 1,
        text: "If you already have a compatible wallet, go to step 2. If not, you can install any crypto wallets including metamask.",
    },
    {
        title: "Load Wallet With Crypto",
        index: 2,
        text: "Load your favorite crypto onto your wallet. ETH, USDT, and BNB are accepted.",
    },
    {
        title: "Buy and Stage $ELUMP",
        index: 3,
        text: "Connect your wallet to the website. Choose the crypto you want to buy $Elump with. Choose the amount of tokens you want to buy and then Buy or Buy and Stake the tokens for maximize your gain.",
    },
    {
        title: "How to check claimable $Elump?",
        index: 4,
        text: "If you have claimable $Elump, you will be able to see the number of tokens you can claim on our website as soon as you connect your wallet. Join the early stage to buy more tokens for cheaper price.",
    },
];
