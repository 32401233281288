import React from "react";
import "./TextSlider.css";
const TextSlider = ({ texts }) => {
  return (
    <div className="text-slider">
      <div className="text-slide">
        <h5
          className="special-head text-center"
          style={{ fontFamily: "'Shantell Sans', sans-serif" }}
        >
          <span className="white_text_slide">TR</span>
          <span className="gold_text_slide">UMP</span>
          <span className="white_text_slide">, </span>
          <span className="gold_text_slide">El</span>
          <span className="white_text_slide">ON, REPUBLICAN ELE</span>
          <span className="gold_text_slide">PHANT</span>
          <span className="white_text_slide"> AND TRI</span>
          <span className="gold_text_slide">UMPH - ELUMPHANT</span>
        </h5>
      </div>
      <div className="text-slide">
        <h5
          className="special-head text-center"
          style={{ fontFamily: "'Shantell Sans', sans-serif" }}
        >
          <span style={{ marginLeft: "10px" }} className="gold_text_slide">
            Triumph, Tech, and Trumpets{" "}
          </span>
          <br />{" "}
          <span className="gold_text_slide">The Ultimate Meme Ride!</span>
        </h5>
      </div>
    </div>
  );
};

export default TextSlider;
