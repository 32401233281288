import Marquee from "react-fast-marquee";
import AboutCard from "../AboutCard";
import "./HowToStart.scss";
import { aboutCardData } from "../../data/data-about/data-aboutCard";
import {
  aboutLogoGif,
  aboutLogoImg,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
} from "../../utils/allImgs";
import TextSlider from "../TextSlider";

const HowToStart = ({ setIsOpen, isOpen }) => {
  const texts = [
    "Slide 1: Hello World",
    "Slide 2: Welcome to React",
    "Slide 3: Enjoy Coding!",
  ];
  return (
    <section className="about_section" id="about">
      <div className="features">
        <div className="features-in__text text-center">Featured In</div>
        <Marquee
          style={{
            color: "black",
            gap: 30,
            backgroundColor: "gold",
            fontSize: "20px",
            marginBottom: "15px",
          }}
        >
          <div style={{ marginRight: "40px" }}>
            <img src={feature1} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature2} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature3} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature4} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature5} width="auto" alt="" />
          </div>
          <div style={{ marginRight: "40px" }}>
            <img src={feature6} width="auto" alt="" />
          </div>
        </Marquee>
      </div>
      <div className="roars_about funny_font">
        {/* <div className="about_mask"></div> */}
        <img src={aboutLogoGif} width={240} height={240} alt="" />
        <div className="about_title">The Meme-Coin That Roars!</div>
        <div className="container_about">
          <div className="row">
            {aboutCardData.map((item, id) => (
              <AboutCard
                key={id}
                img={item.img}
                title={item?.title}
                index={item.index}
                content={item.content}
              />
            ))}
          </div>
        </div>
        <div className="row">
          <div></div>
        </div>
      </div>
    </section>
  );
};

export default HowToStart;
