import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ModalProvider } from "./ContextProvider";
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// basename="template_react"
ReactDOM.render(
  <ModalProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ModalProvider>,
  document.getElementById("root")
);

reportWebVitals();
