import React, { useRef } from "react";
import { IoMdClose } from "react-icons/io";
import {
  coinbaseIcon,
  CongratulationGif,
  ethLogo,
  homeLogoGif,
  metamaskIcon,
  walletconnectIcon,
} from "../../utils/allImgs";
import "./BuyModal.css";
function BuyModal({
  onClose,
  isOpen,
  onConnectMetamask,
  onConnectBestwallet,
  onConnectWalletConnect,
}) {
  const modalRef = useRef(null);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  return (
    <div className="overlay fade show" onClick={handleOverlayClick}>
      <div className="buy_modal_body" ref={modalRef}>
        <div className="buy_modal_mask"></div>
        <div
          onClick={onClose}
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            paddingTop: "15px",
            cursor: "pointer",
          }}
        >
          <div className="" style={{ zIndex: 99, marginRight: 20 }}>
            <IoMdClose color="white" size={30} fontWeight={"bold"} />
          </div>
        </div>
        <div className="buy_modal_content">
          <h5 className="buy_modal_title">
            Congratulations, Elumphant Pioneer! 🎉
          </h5>
          <img src={CongratulationGif} width="30%" height="30%" alt="" />
          <div className="buy_modal_text">
            Welcome to the herd—let's make history,
            <br /> one trumpet blast at a time! 🚀🐘✨
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyModal;
