import { roadmapImg2, roadmapImg1, roadmapImg3, roadmapImg4, aboutLogoImg } from "../../utils/allImgs";

export const roadmapCardData = [
    {
        date: "2024 Q4:",
        type: true,
        content: `1. Smart Contract Development and Mainnet Deployment: Develop and deploy the Elumphant smart contract to the mainnet. \n 2. Build Website: Launch an engaging and user-friendly website. \n 3. Establish Social Channels: Create and grow Twitter, Telegram, and other channels to foster community. \n 4. Presale Starts (Dec 6th): Kick off the presale, with early supporters getting exclusive opportunities. `,
        index: 1,
        img: roadmapImg4,
    },
    {
        date: "2025 Q1",
        type: false,
        content: `1. Presale Ends (Jan 20th): Wrap up the presale and prepare for the next phases. \n 2. Bridge Development and Deployment: Develop and deploy Elumphants seamless cross-chain bridge. \n 3. NFT Art Creation: Create 10,000 NFTs with 4 rarity levels to reward and engage the community. \n 4. Community Engagement Campaigns: Drive community storytelling around Elumphant, especially around Jan 20th, commemorating the Trump administration.`,
        index: 2,
        img: roadmapImg2,
    },
    {
        date: "2025 Q2",
        type: true,
        content: `1. DEX Listings and Liquidity Addition: List $Elump tokens on major decentralized exchanges like Uniswap and PancakeSwap. \n 2. Staking Launch: Launch staking with high APY rewards to encourage holding and strengthen the community. \n 3. NFT Airdrop: Conduct the exclusive NFT airdrop to early buyers and the top 200 rich list.`,
        index: 3,
        img: aboutLogoImg,
    },
    {
        date: "2025 Q3:",
        type: false,
        content: `1. Centralized Exchange Listings: Begin listing on major centralized exchanges to increase accessibility. \n 2. Further Community Growth: Expand community channels with engaging events, meme contests, and giveaways. \n 3. Partnership Announcements: Collaborate with other projects to enhance Elumphant’s ecosystem.`,
        index: 4,
        img: roadmapImg1,
    },
];
