import React, { useRef } from "react";
import "./Modal.css"; // Modal-specific styling
import { IoMdClose } from "react-icons/io";
import { ComingSoonGif, homeLogoGif } from "../../utils/allImgs";

function Modal({ onClose, isOpen }) {
  const modalRef = useRef(null);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  return (
    <div className="overlay fade show" onClick={handleOverlayClick}>
      <div className="modal_body" ref={modalRef}>
        <div
          onClick={onClose}
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            padding: "15px 15px",
            cursor: "pointer",
          }}
        >
          <div className="">
            <IoMdClose color="white" size={30} />
          </div>
        </div>
        <div className="modal_content">
          <h5 className="modal_title">Coming Soon</h5>
          <img src={ComingSoonGif} width={"70%"} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Modal;
