import React from "react";
import "./aboutCard.scss";
const AboutCard = ({ img, title, index, content }) => {
  return (
    <div className="col-12 col-md-6 col-lg-6 col-xl-3">
      <div className="about_card  text-center " data-aos="fade-up">
        {/* <span style={{ marginRight: "10px" }} className="about_card_index">
          <img src={img} width={150} height={50} alt="" />
        </span> */}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <img src={img} width={150} height={50} alt="" />

          <span className="about_card_text">{title}</span>
        </div>
        <p
          style={{
            fontSize: "16px",
            fontFamily: '"Shantell Sans", sans-serif',
            color: "black",
          }}
        >
          {content}
        </p>
      </div>
    </div>
  );
};

export default AboutCard;
