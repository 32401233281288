import { useEffect, useState } from "react";
import { addRemoveClassBody } from '../../utils'

import dataContact from "../../data/data-containers/HomeDemo2/data-contact.json"
import IcoName from '../../data/data-layout/Footer/data-IcoName.json'
import TextFooter from '../../data/data-layout/Footer/data-TextFooter.json'


import {
    FooterPattern,
    FooterLogo,
} from '../../utils/allImgs'

import Header from "../../layouts/Header"
import Footer from '../../layouts/Footer'
import "./Stacking.scss"

const StackingPage = () => {

    useEffect(() => {
        addRemoveClassBody('darker')
    }, [])
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling
        });
    }, []); // Run only on mount
    return (
        <div >
            <Header Title="" />
            <div className="stacking_content" id="stacking">
                <div className="stacking_mask" />

                <div className="stacking-items">

                    <div className="stacking-items__item">
                        <div>
                            <div className="funny-font" style={{ color: "white" }}>Staked Balance</div>
                            <div className="funny-font" style={{ color: "gold" }}>0 $Elumphant</div>
                        </div>
                        <div>
                            <div className="funny-font" style={{ color: "white" }}>Your stakeable</div>
                            <div className="funny-font" style={{ color: "gold" }}>0 $Elumphant</div>
                        </div>
                        <div className="btn btn-warning">
                            Buy And Stack
                        </div>
                    </div>
                    <div className="stacking-items__item">
                        <div>
                            <div className="funny-font" style={{ color: "white" }}>% of Pool
                            </div>
                            <div className="funny-font" style={{ color: "gold" }}>0%</div>
                        </div>
                        <div>
                            <div className="funny-font" style={{ color: "white" }}>Total Staked</div>
                            <div className="funny-font" style={{ color: "gold" }}>999,999 $Elumphant</div>
                        </div>
                    </div>
                    <div className="stacking-items__item">
                        <div>
                            <div className="funny-font" style={{ color: "white" }}>Estimated Rewards
                            </div>
                            <div className="funny-font" style={{ color: "gold" }}>62%</div>
                        </div>

                    </div>
                    <div className="stacking-items__item">
                        <div>
                            <div className="funny-font" style={{ color: "white" }}>Current Rewards</div>
                            <div className="funny-font" style={{ color: "gold" }}>608.82 <span style={{ fontSize: "10px" }}>Per ETH Block</span></div>
                        </div>

                    </div>
                    <div className="stacking-items__item">
                        <div>
                            <div className="funny-font" style={{ color: "white" }}>Total Rewards
                            </div>
                            <div className="funny-font" style={{ color: "gold" }}>0 $Elumphant</div>
                        </div>
                        <div className="btn btn-warning">
                            Clian Rewards
                        </div>
                    </div>
                </div>
            </div>
            <Footer
                FooterPattern={FooterPattern}
                FooterLogo={FooterLogo}
                text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit ducimus voluptatibus neque illo id repellat quisquam? Autem expedita earum quae laborum ipsum ad."
                IcoName={IcoName}
                TextFooter={TextFooter}
            />
        </div>
    );
};

export default StackingPage